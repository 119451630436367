// login
.login
	+animateOpenPage
	
	&-wrapper
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center

		height: 100vh

	&-logo
		margin-bottom: 50px

	&__logo
		width: 250px

	&-form
		width: 40%

		display: flex
		flex-direction: column

	&__input
		margin-bottom: 15px
