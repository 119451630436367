// goods
.goods
	&-search
		padding-bottom: 50px
		border-bottom: 1px solid $gray
		+animateOpenPage

		&__input
			width: 100%

	&-list
		margin-top: 50px

		&-item
			color: $black
			padding: 50px 0
			display: flex
			justify-content: space-between
			align-items: center
			cursor: pointer
			border-bottom: 1px solid $gray
			+animateOpenPage

			&:hover &__title
				color: $mainColor

			&:hover &__title > span
				color: $mainColor

			&:hover &-arrow
				opacity: 1
				transform: rotate(-20deg)

			&-arrow
				opacity: 0
				fill: $mainColor
				+transition

			&__title
				font-size: 24px
				+transition

				& > span
					font-size: 18px
					color: $gray
					padding-left: 15px
					+transition

	&-page
		position: absolute
		top: 0
		left: 0
		width: 100%

		min-height: 100vh

		background-color: #F6F6F6
		+animateOpenLeftPage

		&-top
			margin: 50px 0
			display: flex
			align-items: center
			padding-bottom: 50px
			border-bottom: 1px solid $gray

			&-arrow
				margin-right: 25px
				transform: rotate(180deg)
				+transition
				&:hover
					transform: rotate(200deg)

			&__title
				font-size: 24px

				& > span
					font-size: 18px
					color: $gray
					padding-left: 15px

			&-filters
				padding: 35px

				border-radius: 15px
				background-color: $white

				&-copy-page
					&__subtitle
						display: block
						font-size: 18px
						color: $gray
						margin-bottom: 15px

		&-add
			&-block
				&__btn
					user-select: none
					cursor: pointer
					border: none
					display: flex
					align-items: center
					background-color: transparent
					font-size: 18px
					color: $gray
					+transition

					& > svg
						stroke: $gray
						margin-right: 15px
						+transition

					&:hover
						color: $black

					&:hover svg
						stroke: $black

		&-block
			position: relative
			display: flex
			flex-direction: column
			padding: 50px

			border-radius: 15px
			background-color: $white

			+animateOpenLeftPage
	
			&__input
				margin-bottom: 25px

			&__delete
				position: absolute
				top: 30px
				right: 30px

				user-select: none
				font-size: 16px
				cursor: pointer
				+transition
				&:hover
					color: $red

			&__slider
				position: absolute
				top: 30px
				left: -120px

				user-select: none
				font-size: 16px
				cursor: pointer
				+transition
				&:hover
					color: $gray

		&-subblock
			width: 70%
			position: relative
			display: flex
			flex-direction: column
			padding-bottom: 15px
			border-bottom: 1px solid $gray
			margin: 25px 0
			+animateOpenLeftPage

			&-btn
				margin: 25px 0

			&__title
				font-size: 28px
				margin-bottom: 25px

		&-bottom
			&-btn
				margin-top: 50px
				display: flex
				justify-content: space-between
				align-items: center
				margin-bottom: 50px
