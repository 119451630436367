// mixin
@mixin transition
	transition: all .4s ease

@mixin animateOpenPage
	animation: openPage .4s

@mixin animateOpenLeftPage
	animation: openLeftPage .4s

// interface	
*
	outline: none
	margin: 0
	padding: 0
	box-sizing: border-box

body
	font-size: 15px
	font-family: 'Open Sans', sans-serif
	overflow-y: scroll
	overflow-x: hidden
	&::-webkit-scrollbar
		width: 18px

	&::-webkit-scrollbar-thumb
		background-color: $gray
		border-radius: 15px
		border: 5px solid transparent
		background-clip: content-box
		height: 20px

// link
a
	text-decoration: none
	color: $mainColor

// input
.input
	width: 100%
	position: relative
	z-index: 2
	border-radius: 2px
	border: 1px solid $gray
	background-color: transparent
	padding-top: 15px
	padding-bottom: 15px
	padding-left: 20px
	font-size: 16px
	+transition

	&__error
		border: 1px solid $red

		&::placeholder
			color: $red

	&__label
		display: block
		font-size: 16px
		margin-bottom: 15px
		+transition

		&__error
			color: $red

	&-span__error
		display: block
		margin-top: 10px
		color: $red
		+transition

// select
.select
	width: 100%
	margin-bottom: 25px
	display: flex
	align-items: center

	&:last-child
		margin-bottom: 0

	&__select
		width: 80%
		position: relative
		z-index: 2
		border-radius: 2px
		border: 1px solid $gray
		background-color: transparent
		padding-top: 15px
		padding-bottom: 15px
		padding-left: 20px
		font-size: 16px

	&__label
		display: block
		font-size: 18px
		width: 20%

// btn
.btn
	cursor: pointer
	line-height: 1.5em
	user-select: none
	text-align: center
	display: inline-block
	border-radius: 2px
	border: none
	font-size: 15px
	background-color: $mainColor
	color: $white
	padding: 15px 35px
	+transition
	&:hover
		background-color: #1A17EA

// container
.container
	max-width: 1200px
	width: 90%

	margin: 0 auto

// not found
.not-found
	display: flex
	justify-content: center
	margin-top: 100px
	+animateOpenPage

	&__title
		font-size: 24px
		color: $gray

// img-placeholder
.img-placeholder
	width: 250px
	height: 250px
	margin: 50px 0

	box-shadow: 0 0 10px rgba(0,0,0, 0.2)
	background-position: center center
	background-size: cover
	background-repeat: no-repeat

// keyframes
@keyframes openPage
	0%
		opacity: 0

	100%
		opacity: 1

@keyframes openLeftPage
	0%
		opacity: 0
		left: 150px

	100%
		opacity: 1
		left: 0px

// rich
.rich
	margin-bottom: 25px

	&__label
		display: block
		font-size: 18px
		margin-bottom: 15px

	& span
		font-family: 'Open Sans', sans-serif !important
